import React, { useState, useEffect, useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import presskit from '../../documents/Presskit.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PressKit = () => {
  const [numPages, setNumPages] = useState(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth * 0.9); // 90% of screen width
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Ensure correct size on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div ref={containerRef} style={{ width: '90vw', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Document file={presskit} onLoadSuccess={onDocumentLoadSuccess} loading={<p>Loading...</p>}>
        {numPages &&
          Array.from({ length: numPages }, (_, i) => (
            <div key={i} style={{ marginBottom: '20px' }}> {/* Adds spacing between pages */}
              <Page 
                pageNumber={i + 1} 
                renderTextLayer={false} 
                renderAnnotationLayer={false} 
                width={containerWidth} // Adjust width dynamically without scaling
              />
            </div>
          ))}
      </Document>
    </div>
  );
};

export default PressKit;
