import { useState, useRef, useEffect } from 'react'

import styled from "styled-components";

import texture from '../images/album/Shunkland_Album_Art-23 copy_.png'
import outMarch from '../images/album/outMarch.png'
import albumImg from '../images/album/Shunkland_Album_Art-1.png'
import icon from '../images/album/Shunkland_Album_Art-6.png'
import title from '../images/album/Shunkland_Album_Art-8.png'
import names from '../images/album/Shunkland_Album_Art-22.png'
import flash from '../images/album/goblin_2_1.mp4'
import flash2 from '../images/album/goblin_2_1 copy 2@.mp4'

import Foundee from './Foundee/Foundee';
import BandsInTown from './Foundee/BandsInTown';


import ShunkShop_CartWidget from './shop/ShunkShop_CartWidget';
import ShunkShop_HomeWidget from './shop/ShunkShop_HomeWidget';



// 4779 - 
// 2668 - student admissions 
// Te5h?jR:

// 725996




const HomePageAlbum = () => {

    // timer 1
    const [ timerDone, setTimerDone ] = useState( 0 )
    useEffect(() => {
        setTimeout( () => { setTimerDone( state => state + 1 ); setTimeout( () => { setTimerDone( state => state + 1 ) }, 300 ) },  4000 )
    }, [] )

    // timer 2
    useEffect(() => {
        if (timerDone !== 2) return
        setTimeout( () => { setTimerDone( state => state + 1 ); setTimeout( () => { setTimerDone( state => state + 1 ) }, 300 ) },  2000 )
    }, [ timerDone ] )

    return (
        <HomeContainer>

            { timerDone == 1 && 
                <Video src={flash} muted autoPlay playsInline />
            }


            <ShunkShop_HomeWidget page={'home'}/>
            <ShunkShop_CartWidget page={'home'}/>

            <AlbumSection>
                <Img className={'one'} src={icon} />
                <Img className={'two'} src={title} />
                <Img className={'three'} src={names} />
                <Img className={'four'} src={outMarch} />

                <AlbumCover src={albumImg} />
                <Texture src={texture} />
            </AlbumSection>
            <BandsInTown/>
            {/* <AlbumSection /> */}


        </HomeContainer>
    )
}


export default HomePageAlbum


const Video = styled.video`
    position: absolute;
    top: 0;
    min-height: 150vh;
    min-width: 100vw;
    z-index: 110;
    opacity: 1;
    mix-blend-mode: multiply;

    &&.flash2{
        top: -400px
    }
`

const LINKS = styled.iframe`
    
`

const AlbumSection = styled.div`
    position: relative;
    height: 100vh;
    min-height: 800px;
    z-index: 6;
    /* width: 100px; */
    /* overflow: hidden; */
`

const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%) scale(1.6,1.6);

    @keyframes fadeIn1 {
        0% { opacity:0; };
        66% { opacity:0; };
        100% { opacity:1; };
    }

    @keyframes fadeIn2 {
        0% { opacity:0; };
        66% { opacity:0; };
        100% { opacity:1; };
    }

    @keyframes fadeIn3 {
        0% { opacity:0; };
        66% { opacity:0; };
        100% { opacity:1; };
    }
    @keyframes fadeIn4 {
        0% { opacity:0; };
        66% { opacity:0; };
        100% { opacity:1; };
    }

    &.one{
        max-width: 400px;
        width: 70vw;
        /* max-width: 400px;    */
        

        animation: 2s ease-out fadeIn1;
        /* animation-delay: 100ms; */

    }
    &.two{
        max-width: 400px;
        width: 70vw;
        /* max-width: 400px;    */
        
        animation: 2s ease-out fadeIn2;
        /* animation-delay: 300ms; */

    }
    &.three{
        max-width: 400px;
        width: 70vw;
        /* max-width: 400px;    */
        
        animation: 3s ease-out fadeIn3;
        /* animation-delay: 400ms; */

    }
    &.four{
        max-width: 400px;
        width: 70vw;
        padding-top: 60px;
        /* max-width: 400px;    */
        animation: 4s ease-out fadeIn4;
        /* animation-delay: 400ms; */

    }
    
`

const Texture = styled.img`

    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%) scale(1.6,1.6);
    height: 200%;
    min-width: 100%;
    opacity: 0.5;
    position: absolute;
    pointer-events: none;
`

const HomeContainer = styled.div`

    height: 100%;
    width: 100vw;
    height: fit-content;
    background-color: #d7bc9b;
    position: relative;
    overflow: hidden;
    position: relative;




`



const AlbumCover = styled.img`


    @keyframes opacityStartImg {
        0% {
            opacity: 0;
            /* transform: translate(-50%, -50%) scale(1.3,1.3); */
        }
        40% {
            opacity: 0;
            /* transform: translate(-50%, -50%) scale(1.3,1.3); */
        }
        100% {
            opacity: 1;
            /* transform: translate(-50%, -50%) scale(1.6,1.6); */
        }
    }

    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%) scale(1.6,1.6);
    max-width: 400px;
    width: 70vw;
    animation: 2s ease-out opacityStartImg;

    /* opacity: 0; */

`