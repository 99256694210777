import React from 'react'
import styled from 'styled-components'

const Foundee = () => {
  return (
    <ResponsiveColumn >
        <div class="card foundee-shadow"> 
        <div class="card-top">
            <img class="card-img-top foundee-shadow" alt="Banner" src="https://static.found.ee/user/373202/res-c2fa1d51-6341-42e2-8f66-32b562a3250b-Shunkland-Album-Art--1-.jpg"/>
        </div>                
        <div class="card-body">
            <h4 class="card-title text-center">shunkland</h4>
            <p class="card-text text-center">Pre-save, Out March 7th.</p>
        </div>
        <ul class="list-group list-group-flush">

            <li class="list-group-item">
                <a href="https://api.found.ee/spotify/connect/spotify?presaveId=123590&amp;origin=https%3A%2F%2Ffound.ee%2Fshunkland" onclick="event.preventDefault();
                    sendInitAuthEvent('https://found.ee/shunkland', 'https://api.found.ee/spotify/connect/spotify', '123590');
                    eventsProxy.onPreSave('Spotify', {'retailer_name': 'Spotify Pre-Save', 'cta': 'Pre-Save', 'url': 'https://found.ee/shunkland', 'status': 'INIT'}, () => {
                    trackPreSaveButtonClick('https://found.ee/shunkland/ms-spotifypre-0');
                    preSaveOnClick('https://api.found.ee/spotify/connect/spotify', '123590', 'https%3A%2F%2Ffound.ee%2Fshunkland', '_self');
                    });
                    return false;" target="_blank">
                    <div class="row">
                        <div class="col-8">
                            <img src="https://static.found.ee/static/logo-spotify-presave.svg" alt="Spotify Pre-Save" class="foundee-store-icon"/>
                        </div>
                        <div class="col-4 border-left button">
                            <span>Pre-Save</span>
                        </div>
                    </div>
                </a>
            </li>
            </ul>
        </div>
            <div class="foundee-page-social-icons">
                <a href="https://www.tiktok.com/@shunkland" onclick="eventsProxy.onClickSocialNetworkButton({'network': 'TikTok', 'destination_url': 'https://www.tiktok.com/@shunkland', 'social_network_action': 'Follow'}, null)" target="_blank">
                    <img src="https://static.found.ee/static/tiktok-sm.svg" alt="TikTok" />
                </a>
                <a href="https://www.instagram.com/shunkland/" onclick="eventsProxy.onClickSocialNetworkButton({'network': 'Instagram', 'destination_url': 'https://www.instagram.com/shunkland/', 'social_network_action': 'Follow'}, null)" target="_blank">
                    <img src="https://static.found.ee/static/instagram-sm.svg" alt="Instagram" />
                </a>
                <a href="https://www.youtube.com/@shunkland" onclick="eventsProxy.onClickSocialNetworkButton({'network': 'YouTube', 'destination_url': 'https://www.youtube.com/@shunkland', 'social_network_action': 'Follow'}, null)" target="_blank">
                    <img src="https://static.found.ee/static/youtube-sm.svg" alt="YouTube" />
                </a>
            </div>
        </ResponsiveColumn>
  )
}



const ResponsiveColumn = styled.div`
  /* Base styles for xs */
  width: 50%; /* col-xs-6 (6/12) */
  margin-left: 25%; /* offset-xs-3 (3/12) */

  /* Medium (sm) screens */
  @media (min-width: 576px) {
    width: 50%; /* col-sm-6 (6/12) */
    margin-left: 25%; /* offset-sm-3 (3/12) */
  }

  /* Large (md) screens */
  @media (min-width: 768px) {
    width: 33.33%; /* col-md-4 (4/12) */
    margin-left: 33.33%; /* offset-md-4 (4/12) */
  }
`;



const Card = styled.div`
    border: none;   
    background: transparent !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: white;
    box-shadow: 0 5px 13px rgba(46, 53, 72, 0.17);
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
`

export default Foundee
