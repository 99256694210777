import shirt_1 from '../../images/merch/shirts/telephones/images/shirt_1.jpg'
import shirt_2 from '../../images/merch/shirts/telephones/images/shirt_2.jpg'
import shirt_3 from '../../images/merch/shirts/telephones/images/shirt_3.jpg'
import shirt_4 from '../../images/merch/shirts/telephones/images/shirt_4.jpg'
import shirt_5 from '../../images/merch/shirts/telephones/images/shirt_5.jpg'
import shirt_6 from '../../images/merch/shirts/telephones/images/shirt_6.jpg'
import shirt_7 from '../../images/merch/shirts/telephones/images/shirt_7.jpg'
import bear_1 from '../../images/merch/keychains/bear/images/bear_1.jpg'
import bear_2 from '../../images/merch/keychains/bear/images/bear_2.jpg'
import bear_3 from '../../images/merch/keychains/bear/images/bear_3.jpg'
import bear_4 from '../../images/merch/keychains/bear/images/bear_4.jpg'
import bear_5 from '../../images/merch/keychains/bear/images/bear_5.jpg'

import { v4 as uuidv4 } from 'uuid';

import ampgirls from "../../images/merch/shirts/ampgirls/styled.jpg"
import amphangin from "../../images/merch/shirts/ampgirls/hangin.jpg"
import goblinclose from "../../images/merch/shirts/goblinfruit/close.jpg"
import goblinhangin from "../../images/merch/shirts/goblinfruit/hangin.jpg"
import goblinfruit from "../../images/merch/shirts/goblinfruit/styled.jpg"
// --------------------- Structs


class Shop_Item_Details{
    constructor(
        title,
        description,
        price,
        shipping_price,
        images,
        videos,
        sizes,
        versions,
        versionType,
        tags,
        links,
        item_type_id
        ){

        this.title = title
        this.description = description
        this.price = price
        this.shipping_price = shipping_price
        this.images = images
        this.videos = videos
        this.sizes = sizes
        this.versionType = versionType
        this.versions = versions
        this.tags = tags
        this.links = links
    }
}


class Shop_Item{
    constructor(type, details, id){
        this.type = type
        this.details = details
        this.id = id
    }
}

class Order_Item{
    constructor(
        item,
        quantity,
        size, 
        color,
        extras
    ){
        this.item = item
        this.quantity = quantity || 1
        this.size = size || 'small'
        this.color = color || null
        this.extras = extras || null
        
    }

    transformItemForPaypal = () => {

        const price = parseFloat(this.item.details.price)
        let transformedForPaypal = 
            {
                reference_id : uuidv4(),
                description: 
                '// ITEM: ' 
                + this.item.id 
                + ' // QUANTITY: ' 
                + this.quantity
                + ' // SIZE: ' 
                + this.size
                + ' // COLOR: ' 
                + this.color
                + ' // EXTRAS: ' 
                + this.extras,

                amount: {
                    currency_code: "CAD",
                    value: price * this.quantity
                }
            }
        return transformedForPaypal
    }
}


// {
//     reference_id : 'Test_1',
//     description: "Shunk Shirt Temp",
//     amount: {
//         currency_code: "CAD",
//         value: 26.50
//     }
// }


///---------------------- Items



const DETAILS_SHUNKLAND_WHITE_T = new Shop_Item_Details(
    "Amp Girls",
    "K_A_P_P_A",
    "35",
    "10",
    [ //images
        ampgirls,
        amphangin
    ],
    [],
    ['small', 'medium', 'large', 'XL'],
    '',
    [],
    [],
    ['shunkland.com'],



)

const DETAILS_SHUNKLAND_BLACK_T = new Shop_Item_Details(
    "Goblin Fruit",
    "Come buy, come buy",
    "25",
    "10",
    [ // images
        goblinfruit,
        goblinclose,
        goblinhangin
    ],
    [],
    ['small', 'medium', 'large', 'XL'],
    '',
    [],
    [],
    ['shunkland.com'],


)




const DETAILS_Telephone_Shirt = new Shop_Item_Details(
    'Telephone T',
    `Hello? Hello...? Who is This? ... How did you get this number? 
    Hello?`,
    '25',
    '10',
    [
        shirt_1,
        shirt_2,
        // shirt_3,
        // shirt_4,
        // shirt_5,
        shirt_6,
        // shirt_7 
    ],
    [],
    ['small', 'medium', 'large', 'XL'],
    '',
    [],
    ['shirt', 'telephone', 't-shirt', 'tshirt', 'wearable'],
    ['shunk.com'],
)


const DETAILS_Bear_Keychain = new Shop_Item_Details(
    'Bear Keychain',
    `🎵 Dingle Dangle, jingley jangle - a'ringly dingly dongly -dooo you have some honey, mate? 🎵 `,
    '5',
    '2',
    [
        bear_1,
        bear_2,
        bear_3,
        bear_4,
        // bear_5,
    ],
    [],
    [],
    '',
    [],
    ['accessories', 'keychain', 'bear', 'misc', 'wearable'],
    ['shunk.com'],
)


const ITEM_Telephone_Shirt = new Shop_Item( 'shirt', DETAILS_Telephone_Shirt, "tele_sh1")
const ITEM_Bear_Keychain = new Shop_Item( 'keychain', DETAILS_Bear_Keychain, "bear_keych1")
const ITEM_SHUNKLAND_WHITE_T = new Shop_Item( 'shirt', DETAILS_SHUNKLAND_WHITE_T, "ampgirls_white")
const ITEM_SHUNKLAND_BLACK_T = new Shop_Item( 'shirt', DETAILS_SHUNKLAND_BLACK_T, "goblinfruit_black")



const shop_item_tags = [
    'shirts',
    'sweaters',
    'accessories',
    'keychains',
    'CDs',
    'tapes',
    'tickets',
    'exclusives'
]

const items = [
    ITEM_SHUNKLAND_BLACK_T,
    ITEM_SHUNKLAND_WHITE_T,            
    // ITEM_Telephone_Shirt,
    // ITEM_Bear_Keychain,
]

export { 
    ITEM_Telephone_Shirt,
    ITEM_Bear_Keychain,
    ITEM_SHUNKLAND_WHITE_T,
    ITEM_SHUNKLAND_BLACK_T,
    shop_item_tags,
    items,
    Order_Item
 } 