import styled from "styled-components";


import image from '../images/grocers.jpg'

import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { NavLink } from "react-router-dom";


import Post from "./SubComponents/Post";

//tennis
import Tennis from "../images/logoTennis.png"
import Court from "../images/court.jpeg"
import Tball from "../images/Tball.png"

//poker
import Ace from "../images/Ace.png"
import PokerBG from "../images/pokerBG__.jpg"
import PokerChip from "../images/pokerchip.png"

//wizards
import wizards from "../images/wizards_bg.jpg"
import Lightning from "../images/lightning.png"

//showPosters
import firstShow from "../images/1stShow.jpg"
import secondShow from "../images/2ndShow.png"
import thirdShow from "../images/3rdShow.jpg"
import fourthShow from "../images/4thShow.jpg"
import fifthShow from "../images/5thShow.jpg"
import sixthShow from "../images/6thShow.jpg"
import seventh from "../images/7th.jpg"
import eighth from "../images/8th.jpg"
import ninth from "../images/ninth.jpg"
import tenth from "../images/tenth.jpg"


import ahrengondola from "../images/show_posters/ahrengondola.jpg"
import burnkob from "../images/show_posters/burnkob.jpg"
import confpoolpub from "../images/show_posters/confpoolpub.jpg"
import jackroof from "../images/show_posters/jackroof.jpg"
import lemonbopmulch from "../images/show_posters/lemonbopmulch.jpg"
import PubertyCess from "../images/show_posters/PubertyCess.jpg"
import steele from "../images/show_posters/steele.jpg"
import tinyfest from "../images/show_posters/tinyfest.jpg"
import warpark from "../images/show_posters/warpark.jpg"
import yoodooright from "../images/show_posters/yoodooright.jpg"
import zoobombs from "../images/show_posters/zoobombs.jpg"
import NewColossus from "../images/show_posters/NewColossus.png"
import jukeez from "../images/show_posters/jukeez.jpg"

import BG from "../images/BG_.jpg"

// import taverne_tour_poster from "../images/show_posters/taverne_tour_2024.jpg"
import taverne_tour_poster from "../images/show_posters/taverne_tour_2024_small.png"

import horseSecondShow from "../images/horsePoster.png"

import ShunkLogo from "../images/shunk_logo.svg"

import SpotifyLogo from "../images/Spotify_Primary_Logo_RGB_Green.png"

import SoundCloudLogo from "../images/soundcloudLogo.png"


//https://fontmeme.com/text-styles/


const tempData = [
    {
        type: 'img',
        src: image,
        link: '',
        text: 'shunkGroceries',
        class: '',
        id: 'mainImg',
        style: {
            top: 0,
            left: 0,
        },
        children: []
    },
    
    {
        type: 'h1',
        src: undefined,
        link: '',
        text: 'Shunk',
        class: '',
        id: 'HomePageTitle',
        style: {
            top: 0,
            left: 0,
        },
        children: []
    },
]


//edit all itmes via their state
//Search item by unique ID
// recursive search function 

const themes = [
    'tennis',
    'poker',
    'wizards'
]


const HomePage = () => {


    const NUM_BALLS = 10

    let tempSelection = undefined

    const wrapperEventRef = useRef()
    const last = useRef()
    
    const [ ballState, setballState ] = useState([])
    const [ elementsState, setEleemntsState ] = useState(tempData)
    const [ clickInitialLocation, setClickInitialLocation ] = useState( [0,0] )
    const [ clickedItemState, setClickedItemState ] = useState(undefined)
    const [ mouseClickState, setMouseClickState ] = useState(false)
    const [ movePosState, setMovePosState ] = useState(false)
    const [ themeState, setThemeState ] = useState(undefined)
    const [ floatImg, setFloatImg ] = useState(undefined)
    const [ backgroundImg, setBackgroundImg ] = useState(undefined)
    const [ shunkTextImg, setShunkTextImg ] = useState(undefined)
    const [ resize, setResize ] = useState(false)


    const [ itemClickedInitLocation, setItemClickedInitLocation ] = useState( [0,0] )

    const [ itemPositionsState, setPositionsState ] = useState(
        {
            'mainImage': [],
            'shunkTitle': [],
        }
    )


    const chosenTheme = () => {
    let random = Math.floor(Math.random()*themes.length)
    if(themes[random] == themeState){
        return chosenTheme()
    }

    switch(themes[random]){
        case 'tennis':
            setFloatImg(Tball)
            setBackgroundImg(Court)
            setThemeState('tennis')
            return
        case 'poker': 
            setFloatImg(PokerChip)
            setBackgroundImg(PokerBG)
            setThemeState('poker')
            return
        case 'wizards':
            setFloatImg(Lightning)
            setBackgroundImg(wizards)
            setThemeState('wizards')
            return
    }
}
useEffect(() => {
    if(!themeState){
        chosenTheme()        
    }
},[])

    const randomScreenPos = () => {
        return (
            { 
                top : (Math.random() * window.innerHeight)-40,
                left : (Math.random() * window.innerWidth)-40
            }
            )
    }        
    
    const packBalls = () => {
        console.log('hello')
        let balls = []
        for( let i=0 ; i < NUM_BALLS; i++){
            balls.push(randomScreenPos())
        } 
        setballState(balls)
    }

    useEffect(() => {
        if(last.current){
            packBalls()
        }

    }, [last]);

    

    const handleMouseDown = (e) => {
        
            e.preventDefault()
            let element = e.srcElement
            console.log(element.classList)
            if( element.classList.value.includes('nodrag') ) return

            let elementPostion = element.getBoundingClientRect()

            setClickInitialLocation([e.clientX, e.clientY])
            setClickedItemState(e.srcElement)
            setItemClickedInitLocation([ elementPostion.left, elementPostion.top ])
            setMouseClickState(true)  

    }

    const handleMouseMove = (e) => {

        if(mouseClickState){
            if(!tempSelection){
                clickedItemState.style.left = `${itemClickedInitLocation[0] + (e.clientX - clickInitialLocation[0])}px`
                clickedItemState.style.top = `${itemClickedInitLocation[1] + (e.clientY - clickInitialLocation[1]) }px`

                setMovePosState([clickedItemState.style.left, clickedItemState.style.top])
            }
        }
    }

    const handleMouseUp = (e) => {
        setMouseClickState(false)
        setClickedItemState(undefined)
        setItemClickedInitLocation([0,0])
        setClickInitialLocation([0,0])
    }

    const handleResize = () => {
        setResize(state => !state)
    }

    // add and remove event listeners to page
    useEffect(() => {

        document.addEventListener('mousedown', handleMouseDown)
        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('resize', handleResize)
        
        return(
            () => {
                document.removeEventListener('click', handleMouseDown)
                document.removeEventListener('mouseup', handleMouseUp)
                document.removeEventListener('mousemove', handleMouseMove)
                window.removeEventListener('resize', handleResize)
            }
        )

    },[mouseClickState])

    return(
        <HomePageWrapper 
            // style={{backgroundImage: backgroundImg}} 
            ref={wrapperEventRef}>
            <>
                <TitleDiv>
                    {/* <ShunkImg src={Tennis}></ShunkImg> */}
                    <ShunkLogoIMG src={ShunkLogo} alt='howdy'/>
                    <ShunkLogoIMG2 src={ShunkLogo} alt='howdy'/>
                </TitleDiv>

                <BallDiv>
                {   
                    ballState.map((ball, i) => {
                        return(
                            <TBallImg 
                                key={i} 
                                // onClick={() => {packBalls(); chosenTheme()}}
                                src={floatImg} style={{position: 'fixed', top: ball.top, left: ball.left, width: '40px'}}></TBallImg>
                        )
                    })
                }
                </BallDiv>

                <Background ref={last} src={BG}></Background>
                {/* <Footer></Footer> */}
                <Content>
                    <div>
                        <ICONLINK href="https://open.spotify.com/artist/7vDjs6C6FsJD7C2IFduA32">
                            <ICONIMG src={SpotifyLogo}/>
                        </ICONLINK>
                    </div>
                    <div>
                        <ICONLINK href="https://soundcloud.com/shunk-weh/sets/shunk-lp-master/s-BgkqbDLGbTg">
                            <ICONIMG src={SoundCloudLogo} style={{ filter: 'invert(100%)' }}/>
                        </ICONLINK>
                    </div>
                </Content>

                {/* <Content>
                    <Nav>
                        <NavButton to={'/pricemaster'}>Price Master</NavButton>
                        <NavButton to={'/Band'}>The Band</NavButton>

                        <NavButton to={'/DentalRecords'}>Dental Records</NavButton>
                    </Nav>
                </Content> */}

            <Content>

                <Post title={'Strange ski lifts'} img={ahrengondola} link={'/'} />
                <Post title={'Out in the 6'} img={burnkob} link={'/'} />
                <Post title={"Montreal's Notorious"} img={confpoolpub} link={'/'} />
                <Post title={"On the Jackroof"} img={jackroof} link={'/'} />
                <Post title={"Drake never sounded so raw"} img={lemonbopmulch} link={'/'} />
                <Post title={"Niche"} img={PubertyCess} link={'/'} />
                <Post title={"At the Misty Dock"} img={jukeez} link={'/'} />
                <Post title={"A marriage of styles"} img={steele} link={'/'} />
                <Post title={"Far from small"} img={tinyfest} link={'/'} />
                <Post title={'Sounds from the hole'} img={warpark} link={'/'} />
                <Post title={'Damn'} img={yoodooright} link={'/'} />
                <Post title={'爆発的な夜'} img={zoobombs} link={'/'} />
                <Post title={'RIP Steve Whiteman'} img={NewColossus} link={'/'} />
            
                <Post title={'Touring The Tavernes'} img={taverne_tour_poster} link={'/show/taverne_tour'} 
                    glow={true} 
                    />
                <Post title={'Bug CLUB!'} img={tenth} link={'/show/nov7_2023'} />
                <Post title={'Gloin and Grimstreaker'} img={ninth} link={'/show/oct6_2023'} />
                <Post title={'Private Lives & Mr. Power'} img={eighth} 
                link={'/show/eighth'} 
                />
                <Post title={'Fruit lo0ops - Fresh Wax'} img={seventh} link={'/show/seventh'} />
                <Post title={'*****'} img={sixthShow} link={'/show/sixth'} />
                <Post title={'Fifth...Show'} img={fifthShow} link={'/show/fifth'} />
                <Post title={'Fourth.'} img={fourthShow} link={'/show/fourth'} />
                <Post title={'Third Show'} img={thirdShow} link={'/show/third'}/>

                {/* POSTS */}
                <Post title={'Second Show'} img={secondShow} link={'/show/foufs'}/>    
                {/* <Post title={''} img={horseSecondShow}/> */}
                <Post title={'First Show'} img={firstShow} link={'/show/first'} />

            </Content>
            
            </>
        </HomePageWrapper>
    )
}





const ICONIMG = styled.img`
    transition: width 0.2s;
    &&:hover{
        width: 80px;
        top: -80px;
    }
`

const ICONLINK = styled.a`

`


const ShunkLogoIMG = styled.img`
    transform: scale(0.92);
    fill: white;
    filter: invert(100%) sepia(100%);
    position: absolute;
    /* left: 4px; */
    top: 2px;

`

const ShunkLogoIMG2 = styled.img`
    transform: scale(0.92);
    fill: white;
    filter: invert(60%);
    position: absolute;
    /* left: 4px; */
    top: 2px;
    opacity: 30%;
`

const Content = styled.div`
    z-index: 100;   
    width: 80%;
    background-color:rgba(0, 0, 0, 0.2);
    z-index: 100;
    min-width: 300px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    /* max-height: 4000px; */
    /* pointer-events: none; */
    color: white;

    && img{
        width: 70px;
    }

    /* overflow-y: scroll; */

    /* min-height: 50px; */
    /* overflow-y: scroll; */
    &&::-webkit-scrollbar {
        display: none;
    }
    /* @media (min-width : 932px) {
        max-width: 800px;
    } */
    border-radius: 5px;

`


const BallDiv = styled.div`
    /* z-index: 1000; */
    position: absolute;
    width: 90vw;
    height: 100vh;
    overflow-x: hidden;
    /* z-index: 1000; */

`
const TitleDiv = styled.div`
    color: white;
    /* margin: 5vh; */
    width: 80%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    pointer-events: none;
    max-height: 150px;
    height: 20vw;
    margin-bottom: 10px;

    /* @media (max-width: 549px) {

    } */
`

const Title = styled.h1`

    box-sizing: border-box;
    color: red;
    /* height: 100%; */
    /* text-align: top; */
    /* padding-top: 1vh; */
    font-size: 169px;

    @media screen and (max-width: 1000px){
        color: red;
    }
    @media screen and (max-width: 800px){
        color: red;
        font-size: 21.1vw;
        /* height: 100%; */
        /* padding-top: 20%; */
    }
`

const ShunkImg = styled.img`
    height: 169px;
    z-index: 1000;
    pointer-events: none;
    @media screen and (max-width: 800px){
        color: red;
        height: 21.1vw;
        /* height: 100%; */
        /* padding-top: 20%; */
    }
`



const Nav = styled.div`
    width: 100%;
    height: 50px;
    background-color:rgba(255, 100, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

`

const NavButton = styled(NavLink)`

    width: 100px;
    /* height: 80%; */
    background-color: blue;
    margin: 0 0px 0 0px;

	background: #7C7C7C;
	border-bottom: 3px inset rgba(0,0,0,.5);
	border-left: 3px inset rgba(0,0,0,.5);
	border-right: 3px inset rgba(255,255,255,.5);
	border-top: 3px inset rgba(255,255,255,.5);
	box-sizing: border-box;
	color: white;
    text-decoration: none;
	cursor: pointer;
	display: inline-block;
	/* font-size: 2.2rem; */
	margin: 0 5%;
	min-width: 25%;
	padding: .3rem 0;
	/* text-transform: uppercase; */
	width: auto;
    /* font-size: 25%; */
    text-align: center;
    font-size: 90%;
    /* padding-bottom: 15px; */

	
	&:focus,
	&:hover {
		background: #BCBCBC;
}

`


const MainImageStyle = styled.img`
    position: absolute;
`

// const H1Style = styled.h1`
//     color: white;
//     position: absolute;
//     z-index: 100;
// `

const CanvasStyle = styled.canvas`
    height: 100vh;
    width: 100vw;
    background-color: maroon;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -10;
`

const Background = styled.img`
    /* height: 100vh; */
    width: 100vw;
    position: fixed;
    pointer-events: none;
    z-index: -1;
    opacity: 0.1;
    
`

const TBallImg = styled.img`
    position: absolute;
    /* z-index: ; */
`
const HomePageWrapper = styled.div`
    color: red;
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
`


const Footer = styled.div`
    z-index: 100;
    height: 400px;
    width: 100%;
    background-color: green;
    pointer-events: none;
`


export default HomePage;

