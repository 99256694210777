import React, { useEffect, useState } from 'react';

const BandsInTown = () => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://widgetv3.bandsintown.com/main.min.js";
      script.async = true;
      script.onload = () => setIsScriptLoaded(true); // Ensure script is loaded
      document.body.appendChild(script);
  
      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    }, []);

  return (
    <div id="bitwidget">
        {isScriptLoaded ? (
        <a
            className="bit-widget-initializer"
            data-artist-name="id_1323899"
            data-events-to-display=""
            data-background-color="rgba(215,188,155,1)"
            data-separator-color="rgba(54,31,31,1)"
            data-text-color="rgba(54,31,31,1)"
            data-font="Monaco"
            data-auto-style="true"
            data-button-label-capitalization="uppercase"
            data-header-capitalization="uppercase"
            data-location-capitalization="uppercase"
            data-venue-capitalization="uppercase"
            data-display-local-dates="true"
            data-local-dates-position="tab"
            data-display-past-dates="true"
            data-display-details="false"
            data-display-lineup="false"
            data-display-start-time="false"
            data-social-share-icon="false"
            data-display-limit="all"
            data-date-format="MMM. D, YYYY"
            data-date-orientation="horizontal"
            data-date-border-color="#4A4A4A"
            data-date-border-width="1px"
            data-date-capitalization="capitalize"
            data-date-border-radius="10px"
            data-event-ticket-cta-size="large"
            data-event-custom-ticket-text="https://www.bandsintown.com/a/1323899-shunk?came_from=257&utm_medium=web&utm_source=artist_event_page&utm_campaign=artist"
            data-event-ticket-text="GET TICKETS"
            data-event-ticket-icon="false"
            data-event-ticket-cta-text-color="rgba(215,188,155,1)"
            data-event-ticket-cta-bg-color="rgba(54,31,31,1)"
            data-event-ticket-cta-border-color="rgba(54,31,31,1)"
            data-event-ticket-cta-border-width="0px"
            data-event-ticket-cta-border-radius="200px"
            data-sold-out-button-text-color="rgba(54,31,31,1)"
            data-sold-out-button-background-color="rgba(207,68,68,1)"
            data-sold-out-button-border-color="rgba(207,68,68,1)"
            data-sold-out-button-clickable="true"
            data-event-rsvp-position="left"
            data-event-rsvp-cta-size="medium"
            data-event-rsvp-only-show-icon="false"
            data-event-rsvp-text="RSVP"
            data-event-rsvp-icon="false"
            data-event-rsvp-cta-text-color="rgba(215,188,155,1)"
            data-event-rsvp-cta-bg-color="rgba(54,31,31,1)"
            data-event-rsvp-cta-border-color="rgba(54,31,31,1)"
            data-event-rsvp-cta-border-width="1px"
            data-event-rsvp-cta-border-radius="2px"
            data-follow-section-position="top"
            data-follow-section-alignment="center"
            data-follow-section-header-text="Get updates on new shows, new music, and more"
            data-follow-section-cta-size="medium"
            data-follow-section-cta-text="FOLLOW"
            data-follow-section-cta-icon="false"
            data-follow-section-cta-text-color="rgba(215,188,155,1)"
            data-follow-section-cta-bg-color="rgba(54,31,31,1)"
            data-follow-section-cta-border-color="rgba(54,31,31,1)"
            data-follow-section-cta-border-width="0px"
            data-follow-section-cta-border-radius="200px"
            data-play-my-city-position="bottom"
            data-play-my-city-alignment="center"
            data-play-my-city-header-text="Don’t see a show near you?"
            data-play-my-city-cta-size="medium"
            data-play-my-city-cta-text="REQUEST A SHOW"
            data-play-my-city-cta-icon="false"
            data-play-my-city-cta-text-color="rgba(215,188,155,1)"
            data-play-my-city-cta-bg-color="rgba(54,31,31,1)"
            data-play-my-city-cta-border-color="rgba(54,31,31,1)"
            data-play-my-city-cta-border-width="0px"
            data-play-my-city-cta-border-radius="200px"
            data-optin-font=""
            data-optin-text-color=""
            data-optin-bg-color=""
            data-optin-cta-text-color=""
            data-optin-cta-bg-color=""
            data-optin-cta-border-width=""
            data-optin-cta-border-radius=""
            data-optin-cta-border-color=""
            data-language="en"
            data-layout-breakpoint="900"
            data-app-id=""
            data-affil-code=""
            data-bit-logo-position="hidden"
            data-bit-logo-color="rgba(54,31,31,1)"
        ></a>
      ) : (
        <p>Loading BandsInTown widget...</p>
      )}
    </div>
  );
};

export default BandsInTown;
